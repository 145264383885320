import React, { useState, useEffect } from 'react'
import './NavbarDashboard.css'
import { Link as Anchor, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBook, faImage, faAddressBook, faTachometerAlt, faCode, faTable, faClipboardList, faBusinessTime, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../images/logo.png'
import Logout from '../Logout/Logout';

export default function Navbar() {
    const location = useLocation();


    return (

        <div class="navbarDashboard " >
            <Anchor className='logo'  to={`/`}>
                <img src={logo} alt="logo" /> 
                <span>Website</span>
            </Anchor>
            <div className='links'>
                <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faHome} /> Inicio</Anchor>
                <Anchor to={`/dashboard/mesas`} className={location.pathname === '/dashboard/mesas' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faTable} /> Mesas</Anchor>
                <Anchor to={`/dashboard/pedidos`} className={location.pathname === '/dashboard/pedidos' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faClipboardList} /> Pedidos</Anchor>
                <Anchor to={`/dashboard/reservas`} className={location.pathname === '/dashboard/reservas' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faCalendarCheck} /> Reservas</Anchor>
                <Anchor to={`/dashboard/productos`} className={location.pathname === '/dashboard/productos`' ? 'activeLink h-10' : 'h-10'} ><FontAwesomeIcon icon={faBook} /> Productos</Anchor>
                <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                <Anchor to={`/dashboard/sucursales`} className={location.pathname === '/dashboard/sucursales' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faBusinessTime} /> Sucursales</Anchor>
                <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faAddressBook} /> Contacto</Anchor>
                <Anchor to={`/dashboard/usuarios`} className={location.pathname === '/dashboard/usuarios' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faUser} /> Usuarios</Anchor>
                <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faCode} /> Codigos</Anchor>
                <Anchor to={`/dashboard/postalcode`} className={location.pathname === '/dashboard/postalcode' ? 'activeLink h-10' : 'h-10'}><FontAwesomeIcon icon={faCode} />Codigo Postal</Anchor>
            </div>

            <Logout />

        </div>

    );
}
