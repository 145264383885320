import React from 'react';

const Ticket = React.forwardRef((props, ref) => {
  const { pedido } = props;
    const Separator = ()=>{
        return (<span className='text-center'> ------------------------------------------------------- </span> )
    }
  return (
    <div id='datos-ticket' ref={ref} className={'flex flex-col gap-1 p-4 max-w-[330px] text-sm ' + `${props?.className}`} style={{ fontFamily: 'Arial' }}>
        <div className='w-full text-center '>
            <b>Restaurante {pedido?.sucursal || "Torrejon"}</b>
        </div> 
        <Separator />
        <span>Cliente: <b className='uppercase'> {pedido?.cliente}</b><br className='d-none' /></span>
        <span>Fecha: <b>{pedido?.fecha}</b><br className='d-none' /> </span> 
        <span>Pedido #<b>{pedido?.numero}</b> <br className='d-none' /></span> 
        <Separator />
        <div className=''>
            <div className='mb-2 '> 
                <div className='flex justify-between text-left gap-2 font-bold'> 
                    <div className='flex text-left gap-2'> 
                        <span>Cant</span>
                        <span>Descripcion</span>
                    </div>
                    <div className='flex text-left gap-2'> 
                        <span>P.Unit</span>
                        <span>-</span>
                        <span>Total</span>
                    </div> 
                </div>
                
                <div className='flex w-full text-right justify-end text-left flex-wrap text-sm'> 
                <Separator />
                </div> 
            </div>
            {
                pedido.items
                .map((item, index) => (
                    <div key={index} className='mb-2 '> 
                        <div className='flex justify-between text-left gap-0'> 
                            <div className='flex text-left gap-1'> 
                                <span>{item.cantidad} x </span>
                                <span>{item.nombre}</span>
                            </div>
                            <div className='flex text-left gap-1'> 
                                <span>{item.precio.toFixed(2)} €</span>
                                <span>-</span>
                                <span className='font-[500]'>{(item.precio * item.cantidad).toFixed(2)} €</span>
                            </div> 
                        </div>
                        {
                            item.notas != '' && 
                            <div className='flex w-full text-right justify-end text-left flex-wrap text-sm'> 
                                <span> *  { item.notas } </span> 
                            </div>
                        }
                    </div>
                ))
            }
        </div>
        <Separator />
        <div className='w-full flex justify-end'> 
            <h3>Total: <b>{pedido.total.toFixed(2)} €</b> </h3> 
        </div>
        <Separator />
        <p className='text-center font-bold'>
            Gracias por su compra.
        </p>
    </div>
  );
});

export default Ticket;