import React from 'react'
import Header from '../Header/Header'
import ReservasData from '../../Components/Admin/ReservasData/ReservasData'
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'

export default function Reservas() {
    return (
        <div className='containerGrid'>
            <Header /> 
            <section className='containerSection'>

                <HeaderDash />
                <div className='container'>
                    <ReservasData />
                </div>
            </section>
        </div>
    )
}
