import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import baseURL from '../url';
import './Cart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClockRotateLeft, faClose, faHistory, faShoppingCart, faTimesCircle, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moneda from '../moneda';
import Swal from 'sweetalert2';
import contador from '../contador'
import Cookies from 'js-cookie'; 

export default function Cart() {

	const inputNombreRef = useRef(null)
	const inputEmailRef  = useRef(null)
	const inputPhoneRef  = useRef(null)
	const selectFormaPagoRef  = useRef(null)
	const selectDireccionRef  = useRef(null) 
	const inputCodigoRef  = useRef(null)
	
	const [cartItems, setCartItems] = useState([]);
	const [productos, setProductos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalPedidosIsOpen, setModalPedidosIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsOpen2, setModalIsOpen2] = useState(false);
	const [modalIsOpen3, setModalIsOpen3] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [noteText, setNoteText] = useState('');
	const [addressText, setAddressText] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [codigo, setCodigo] = useState('');
	const [contactos, setContactos] = useState([]);
	const [pedidos, setPedidos] = useState([]);
	const [descuento, setDescuento] = useState(0);
	const [codigoValido, setCodigoValido] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [paymentMethod, setPaymentMethod] = useState('efectivo');
	const [deliveryOption, setDeliveryOption] = useState('delivery');
	const [idCliente, setIdCliente] = useState(() => Cookies.get('idCliente') || 0);
	const [sucursales, setSucursales] = useState([]);
	const [idRestaurate, setIdRestaurante] = useState(() => Cookies.get('idRestaurate') || 0);
	const [deliveryOptionText, setDeliveryOptionText] = useState('Envío a domicilio')

	//para la pasarela de pago
	
	const [Ds_SignatureVersion, setDs_SignatureVersion] = useState('HMAC_SHA256_V1');
	const [Ds_MerchantParameters, setDs_MerchantParameters] = useState('');
	const [Ds_SignatureInitial, setDs_SignatureInitial] = useState('sq7HjrUOBfKmC576ILgskD5srU870gJ7');
	const [Ds_Signature, setDs_Signature] = useState('');

	const [ItemsCartPayment, setItemsCartPayment] = useState([]);
	const [DS_MERCHANT_CURRENCY, setDS_MERCHANT_CURRENCY] = useState('978');
	const [DS_MERCHANT_MERCHANTCODE, setDS_MERCHANT_MERCHANTCODE] = useState('363754805');
	const [DS_MERCHANT_MERCHANTURL, setDS_MERCHANT_MERCHANTURL] = useState('https://chako.ninjastudio.dev/urlNotificacion.php');
	const [DS_MERCHANT_ORDER, setDS_MERCHANT_ORDER] = useState('');
	const [DS_MERCHANT_TERMINAL, setDS_MERCHANT_TERMINAL] = useState('1');
	const [DS_MERCHANT_TRANSACTIONTYPE, setDS_MERCHANT_TRANSACTIONTYPE] = useState('0');
	const [DS_MERCHANT_URLOK, setDS_MERCHANT_URLOK] = useState('https://chako.ninjastudio.dev/urlOK.php');
	const [DS_MERCHANT_URLKO, setDS_MERCHANT_URLKO] = useState('https://chako.ninjastudio.dev/urlKO.php');

	const TemporalPrice = 'price_1QPmUpEMKwAIhKCAU4RBssuO'

	const [waitForPayment, setWaitForPayment] = useState(false);

	
	 

	useEffect(()=>{ 
		const randomOrder = Math.random(1000000,9999999) * 1000000
		const orderString = `00${parseInt(randomOrder)}`
		 
	},[])

	useEffect(()=>{
		 
	},[])
 

	const cargarSucursales = () => {
		fetch(`${baseURL}/sucursalesGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setSucursales(data.sucursales || []);
				console.log(data.sucursales)
			})
			.catch(error => console.error('Error al cargar sucursales:', error));
	};
	const cargarPedidos = () => {
		fetch(`${baseURL}/userPedidos.php?idCliente=${idCliente}`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setPedidos(data.pedidos.reverse() || []);
			})
			.catch(error => console.error('Error al cargar pedidos:', error));
	};




	useEffect(() => {
		cargarSucursales()
		cargarPedidos()
		cargarContacto();
	}, []);
	useEffect(() => {
		// Calcular el precio total al cargar el carrito o al actualizar los productos existingItem.talle
		let totalPriceCalc = 0;
		const cartItemsPayment = []
		cartItems.forEach(item => { 
			totalPriceCalc += item.precio * item.cantidad;
			cartItemsPayment.push({
				//...item, 
				'price': item?.stripe_price || TemporalPrice, // item.price
				'quantity': item.cantidad
			})
		});
		console.log('cartItemsPayment',cartItems)
		setItemsCartPayment(cartItemsPayment)
		setTotalPrice(totalPriceCalc);
	}, [cartItems]);

	const cargarContacto = () => {
		fetch(`${baseURL}/contactoGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setContactos(data.contacto.reverse()[0] || []);
			})
			.catch(error => console.error('Error al cargar contactos:', error));
	};

	useEffect(() => {
		cargarProductos();
	}, [isFocused]);



	useEffect(() => {
		const fetchCartItems = async () => {
			const cart = JSON.parse(localStorage.getItem('cart')) || [];
			const promises = cart.map(async (cartItem) => {
				const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);
				return {
					...producto,
					cantidad: cartItem.cantidad,
					item: cartItem.item,
				};
			});

			Promise.all(promises)
				.then((items) => {
					setCartItems(items);
					setLoading(false);
				})
				.catch((error) => {
					console.error('Error al obtener detalles del carrito:', error);
					setLoading(false);
				});
		};

		fetchCartItems();
	}, [productos, isFocused]);

	const cargarProductos = () => {
		fetch(`${baseURL}/productosGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setProductos(data.productos || []);
			})
			.catch(error => console.error('Error al cargar productos:', error));
	};

	const obtenerImagen = (item) => {
		return item.imagen1 || item.imagen2 || item.imagen3 || item.imagen4 || null;
	};

	const openModal = () => {
		setModalIsOpen(true);
		setIsFocused(true);
	};
	const openModalPedidos = () => {
		setModalPedidosIsOpen(true);
		setIsFocused(true);
	};
	const closePedidosModal = () => {
		setModalPedidosIsOpen(false);
		setIsFocused(false);
	};


	const closeModal = () => {
		setModalIsOpen(false);
		setIsFocused(false);
	};

	const openModal2 = () => {
		setModalIsOpen2(true);
	};

	const closeModal2 = () => {
		setModalIsOpen2(false);
	};
	const openModal3 = () => {
		setModalIsOpen3(true);
	};

	const closeModal3 = () => {
		setModalIsOpen3(false);
	};
	const removeFromCart = (id) => {
		const updatedCart = cartItems.filter(item => item.idProducto !== id);
		setCartItems(updatedCart);
		localStorage.setItem('cart', JSON.stringify(updatedCart));
	};

	const clearCart = () => {
		setCartItems([]);
		localStorage.removeItem('cart');
	};

	const [codigos, setCodigos] = useState([]);

	useEffect(() => {
		cargarCodigos();

	}, []);

	const cargarCodigos = () => {
		fetch(`${baseURL}/codigosGet.php`, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				setCodigos(data.codigos || []);
			})
			.catch(error => console.error('Error al cargar códigos:', error));
	};

	//console.log(cargarCodigos)



	// Función para aumentar la cantidad de un producto en el carrito
	const increaseQuantity = (index) => {
		const updatedCartItems = [...cartItems];
		updatedCartItems[index].cantidad += 1;
		setCartItems(updatedCartItems);
		localStorage.setItem('cart', JSON.stringify(updatedCartItems));
	};

	// Función para disminuir la cantidad de un producto en el carrito
	const decreaseQuantity = (index) => {
		const updatedCartItems = [...cartItems];
		if (updatedCartItems[index].cantidad > 1) {
			updatedCartItems[index].cantidad -= 1;
			setCartItems(updatedCartItems);
			localStorage.setItem('cart', JSON.stringify(updatedCartItems));
		}
	};


	/* realizar pedidos------------------------*/
	const [estado, setEstado] = useState('Pendiente');

	const [mensaje, setMensaje] = useState('');
	const [selectedMesa, setSelectedMesa] = useState('');


	const PayWithCard = () => {
		//hay que guardar el id del pedido y el status del pedido pendiente por pagar

		//setWaitForPayment(false) 
		window.pedidoForm.submit()
	}

	const PayWithCash = async () => {
		try {
			// Construir la lista de productos del pedido
			const productosPedido = cartItems.map(item => {
				return {
					titulo: item.titulo,
					cantidad: item.cantidad,
					item: item.item,
					categoria: item.categoria,
					precio: item.precio,
					imagen: obtenerImagen(item)

				}
			});
			// Convertir la lista de productos a JSON
			const productosPedidoJSON = JSON.stringify(productosPedido);
			// Calcular el precio total del pedido
			let totalPrice = 0;
			cartItems.forEach(item => {
				totalPrice += item.precio * item.cantidad;
			});

			// Obtener el descuento del código de descuento
			const codigoDescuento = codigos.find(item => item.codigo === codigo);
			let descuentoCodigo = 0;

			if (codigoDescuento) {
				descuentoCodigo = codigoDescuento.descuento;
			}

			// Aplicar el descuento del código de descuento
			const totalPriceWithDiscount = totalPrice - descuentoCodigo;
			window.document.href = ('https://chako.ninjastudio.dev')
			
			// Enviar el pedido con el precio total descontado
			const formData = new FormData();
			formData.append('idMesa', 0);
			formData.append('estado', estado);
			formData.append('productos', productosPedidoJSON);
			formData.append('total', totalPriceWithDiscount);
			formData.append('usuario', idCliente);
			formData.append('sucursal', idRestaurate);
			formData.append('nombre', name);
			formData.append('email', email);
			formData.append('phone', phone); 
			formData.append('nota', noteText);
			formData.append('codigo', codigo);
			const response = await fetch(`${baseURL}/pedidoPost.php`, {
				method: 'POST',
				body: formData,
			});

			const data = await response.json();
			if (data.mensaje) {
				setMensaje('');
				Swal.fire(
					'Pedido enviado!',
					data.mensaje,
					'success'
				);
				setName('')
				setEmail('')
				setPhone('')
				setCodigo('')
				setNoteText('')
				closeModal()
				closeModal2()
				closeModal3()
				clearCart()
				cargarPedidos()
				openModalPedidos()
			} else if (data.error) {
				setMensaje('');
				if (!toast.isActive(13, "friendRequest")) {

					toast.error(data.error, { autoClose: 1000 });
				}
			}
		} catch (error) {
			console.error('Error:', error);
			setMensaje('');
			if (!toast.isActive(13, "friendRequest")) {

				toast.error('Error de conexión. Por favor, inténtelo de nuevo.', { autoClose: 1000 });
			}
		}
	}

	const checkForm = async () => {
		 //validar los campos
		const Nombre = inputNombreRef.current.value 
		const Email = inputEmailRef.current.value
		const Telefono = inputPhoneRef.current.value
		const formaPago = selectFormaPagoRef.current.value 
		const Direccion = selectDireccionRef.current.value 
		

		if( Nombre == "" || Nombre == undefined ) return false
		if( Email == "" || Email == undefined ) return false
		if( Telefono == "" || Telefono == undefined ) return false
		if( Direccion == "" || Direccion == undefined ) return false
		if( formaPago == "" || formaPago == undefined ) return false 


		return true
		
	}
	const crearPedido = async (e) => {
		e.preventDefault()
		//return window.href = 'https://chako.ninjastudio.dev'
		const isValidForm = await checkForm()
		if( isValidForm ) {
			const formaPago = selectFormaPagoRef.current.value 
			switch (formaPago){
				case 'card': PayWithCard(); break;
				case 'cash': PayWithCash(); break;
				case 'transfer': PayWithCash(); break;
				default: PayWithCard(); break;
			}
			
		} else {
			Swal.fire(
				'Campos Obligatorios',
				'Debe completar todos los campos!',
				'error'
			);
		}
  
		//setWaitForPayment(true)

		//window.open('https://chako.ninjastudio.dev', '_blank')
		

		//checkPayment()
	};

	const [counter, setCounter] = useState(contador);
	const [isPaused, setIsPaused] = useState(false);
	
	useEffect(() => {
		const interval = setInterval(() => {
			if (!isPaused) {
				setCounter((prevCounter) => {
					if (prevCounter === 1) {
						recargar();
						return contador;
					}
					return prevCounter - 1;
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [isPaused]);
	const togglePause = () => {
		setIsPaused(!isPaused);
	};
	const recargar = () => {


	};
	useEffect(() => {
		const interval = setInterval(() => {
			cargarPedidos()

		}, 15000);
		return () => clearInterval(interval);
	}, [])

	useEffect(() => {
		if (modalPedidosIsOpen) {
			setModalIsOpen(false)
		}
	}, [modalPedidosIsOpen])

	useEffect(() => {
		if (modalIsOpen) {
			setModalPedidosIsOpen(false)
		}
	}, [modalIsOpen])

	const WaitingCartPayment = () =>{
		
		return (
			<div className='WaitingCartPayment '>
				<div className='WaitingCartPaymentContent'>
					<span> 
						Esperando por el procesador de pagos, si ya pago presione "Realizado" 
						para reintentar la confirmacion del pago
					</span> 
					{ /**
					<div  className='flex gap-5 mt-5'> 
						<span>Reintentando ( 1 ) </span> 
					</div>
					  */}
					<div  className='flex gap-5 mt-5'> 
						<button className='text-red-500 cursor-pointer' onClick={()=>setWaitForPayment(true)}> 
							<b>Cancelar</b>  
						</button> 
						<button className='text-black-500 cursor-pointer'> 
							<b>Realizado</b>  
						</button>  
					</div>
				</div>
			</div>
		)
	}


	return (
		<div>
			<ToastContainer containerId={"friendRequest"} />
			<button onClick={openModal} className='cartIconFixed'>
				{
					cartItems?.length >= 1 && (
						<span className='-mr-5 h-[22px] w-[22px] font-[500] p-0 text-center'>
							{cartItems.length}
						</span>
					)

				}
				<FontAwesomeIcon icon={faShoppingCart} />
			</button>
			<button onClick={openModalPedidos} className='cartIconFixed bottom-[120px]'>
				{
					pedidos?.length >= 1 && (
						<span className='-mr-5 h-[22px] w-[22px] font-[500] p-0 text-center'>
							{(pedidos.filter(p => p.estado != 'Entregado' && p.estado != 'Rechazado')).length}
						</span>
					)

				}
				<FontAwesomeIcon icon={faHistory} />
			</button>

			<Modal
				isOpen={modalIsOpen}
				className="modal-cart"
				overlayClassName="overlay-cart"
				onRequestClose={closeModal}
				ariaHideApp={false}
			>
				<div className='deFLex'>
					<button onClick={closeModal} ><FontAwesomeIcon icon={faArrowLeft} />  </button>
					<button onClick={clearCart} className='deleteToCart font-[500]'>Vaciar carrito</button>
				</div>
				{cartItems?.length === 0 ?
					(<p className='nohay'> No hay productos</p>) : 
					(<>
						
						<div className="modal-content-cart">

							{loading ? (
								<p >Cargando...</p>
							) : (
								<div>
									{cartItems.map((item, index) => (
										<div key={`cart-item-${index}`} className='cardProductCart' >
											<Anchor to={`/producto/${item?.idProducto}/${item?.titulo?.replace(/\s+/g, '-')}`} onClick={closeModal}>
												<img src={obtenerImagen(item)} alt="imagen" />
											</Anchor>
											<div className='cardProductCartText'>
												<h3>{item.titulo}</h3>
												<span>
													{item?.item?.map((sabor, index) => (
														<span key={index}> {sabor}</span>
													))}
												</span>
												<strong>{moneda} {item?.precio}</strong>
											</div>
											<div className='deColumn'>
												<button onClick={() => removeFromCart(item.idProducto)} className='deleteCart'>  <FontAwesomeIcon icon={faTrash} /></button>
												<div className='deFlexCantidad'>
													<button onClick={() => decreaseQuantity(index)}>-</button>
													<span>{item.cantidad}</span>
													<button onClick={() => increaseQuantity(index)}>+</button>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						<div className='deColumnCart'>
							<h4>Total: {moneda} {totalPrice.toFixed(2)}</h4>
							<div className='deFLexBtns'>
								<button className='btnPagar' onClick={openModal2}>
									Continuar
								</button>
							</div>
						</div>

						<Modal
							isOpen={modalIsOpen2}
							onRequestClose={closeModal2}
							className="modal-cart"
							overlayClassName="overlay-cart"
							ariaHideApp={false}
						>
							<div className='deFLex'>
								<button onClick={closeModal2} ><FontAwesomeIcon icon={faArrowLeft} />  </button>
								<h4>orden #{DS_MERCHANT_ORDER}</h4> 
							</div> 
							 
							 { waitForPayment && <WaitingCartPayment /> }
							
							<form 
								id="pedidoForm"  
								name="pedidoForm"  
								action="https://chako.ninjastudio.dev/public/create-checkout-session.php" 
								method="POST"
								className="modal-send-form" 
								>
								<input type="hidden" name="cartItems" value={JSON.stringify(ItemsCartPayment)}/>
									<input type="hidden" name="metadata" value={
										{
											"cartItems":
											JSON.stringify(
												cartItems.map((item)=>({
													'cantidad':item.cantidad, 
													'idProducto':item.idProducto,
													'ingredientes':item.ingredientes,
													'precio':item.precio
												}))
											),
											"telefono":phone,
											"direccion":addressText,
											"name":name,
											"email": email
										}
									}/>
								<input type="hidden" name="Ds_MerchantParameters" value={Ds_MerchantParameters}/>
								<input
									ref={inputNombreRef}
									type="text"
									id="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder='Nombre (Requerido)'
									required
								/>

								<input
									ref={inputEmailRef}
									type="email"
									id="email"
									name='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder='E-mail (Requerido)'
									required
								/>
								<input
									ref={inputPhoneRef}
									type="phone"
									id="phone"
									name='telefono'
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									placeholder='Telefono (Requerido)'
									required
								/>

								<div className='deFLexRadio'>
									<label>Formas de pago (Requerido)</label>
									<select  ref={selectFormaPagoRef} >
										<option
											type="radio"
											id="efectivo"
											name="paymentMethod"
											value="card"
											checked={paymentMethod === 'card'}
											onChange={() => setPaymentMethod('card')}
										>
											Tarjeta Debito/Credito
										</option>
										<option
											type="radio"
											id="efectivo"
											name="paymentMethod"
											value="cash"
											checked={paymentMethod === 'cash'}
											onChange={() => setPaymentMethod('cash')}
										>
											Efectivo
										</option>
										<option
											type="radio"
											id="transferencia"
											name="paymentMethod"
											value="transfer"
											checked={paymentMethod === 'transfer'}
											onChange={() => setPaymentMethod('transfer')}
										>
											Transferencia bancaria
										</option>
									</select>

								</div>
								
								<textarea
									ref={selectDireccionRef}
									name='direccion'
									placeholder="Direccion de entrega"
									value={addressText}
									onChange={(e) => setAddressText(e.target.value)}
								/>
								<textarea
									placeholder="Agrega una nota (opcional)"
									value={noteText}
									onChange={(e) => setNoteText(e.target.value)}
								/>

								<div className='deColumnCart'>
									<h4>Total: {moneda} {totalPrice.toFixed(2)}</h4>
									<div className='deFLexBtns'>
										<button className='btnPagar' onClick={crearPedido}>
											Pagar
										</button>
									</div>
								</div>
							</form>
						</Modal>
					</>)}
			</Modal>
			<Modal
				isOpen={modalPedidosIsOpen}
				className="modal-cart "
				overlayClassName="overlay-cart"
				onRequestClose={closePedidosModal}
				ariaHideApp={false}
			>
				<div className='deFLex justify-end'>
					<button onClick={closePedidosModal} ><FontAwesomeIcon icon={faClose} />  </button>
				</div>
				{pedidos?.length === 0 ?
					(<p className='nohay'> No hay pedidos</p>)
					: (<>
						<div className="modal-content-cart ">
							{loading ? (
								<p >Cargando...</p>
							) : (
								<div className=''>
									<h3 className='font-bold  text-lg my-2'>En proceso</h3>
									{pedidos.map((item, index) => {
										const temporalProducts = JSON.parse(item?.productos) || []

										const sucursal = sucursales?.filter(s => s.id == Number(item.sucursal))
										if (item?.estado != 'Rechazado' && item?.estado != 'Entregado') {

											return <div key={`cart-item-${index}`} className='cardProductCart' >
												<Anchor to={`/producto/${item?.idProducto}/${item?.titulo?.replace(/\s+/g, '-')}`} onClick={closeModal}>
													<img src={temporalProducts[0]?.imagen} alt="imagen" />
												</Anchor>
												<div className='cardProductCartText'>
													<h3>Pedido #00{item.idPedido} <span>({sucursal?.[0]?.nombre})</span></h3>
													<strong>Estado: {` `}
														<strong style={{ color: item.estado == 'Rechazado' ? 'red' : item.estado == 'Entregado' ? 'green' : 'Black' }}>
															{item.estado}
														</strong>
													</strong>
													<strong>{item?.createdAt}</strong>
												</div>
												<div className='deColumn'>
													{item?.estado == 'Pendiente' &&
														<button onClick={() => removeFromCart(item.idProducto)} className='deleteCart'>  {/*<FontAwesomeIcon icon={faTrash} />*/} </button>
													}
												</div>
											</div>
										}
									})}
									{
										pedidos.filter(item => item.estado == 'Pendiente').length === 0 && 
										<h4>
											No hay pedidos en curso
										</h4>
									}
									 
								

								</div>
							)}
						</div>

					</>)}

			</Modal>

		</div >
	);
}



