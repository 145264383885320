import React, { useState, useEffect,useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import baseURL from '../url';
import Ticket from '../Ticket/Ticket';
import { formatedDate } from '../../Helpers/helpers';


const pedidoDataDefault = {
  numero: 1234,
  cliente: "Jesus F.",
  fecha:'05-12-2024',
  items: [
      { 
          nombre: 'Hamburguesa', 
          cantidad: 2, 
          precio: 5.99, 
          notas:'Sin cebolla, sin repollo sin mostaza'
      },
      { 
          nombre: 'Papas Fritas', 
          cantidad: 1, 
          precio: 2.49, 
          notas:'Con bastante salsa de tomate'
      },
  ],
  total: 14.47,
}


const Success = () => {
  const [session, setSession] = useState({});
  const [produtList, setProductList] = useState([]);
  const [ paymentStatus, setPaymentStatus ] = useState('')
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');
  const [idCliente, setIdCliente] = useState(() => Cookies.get('idCliente') || 0);
  const [idRestaurate, setIdRestaurante] = useState(() => Cookies.get('idRestaurate') || 0);
  const ticketRef = useRef(null)

  const [pedidoData,setPedidoData] = useState(pedidoDataDefault)
  



  const aceptar =()=>{

    //ejecutas aceptarr
    



  }






  const getProductList = async () =>{
    const url = `${baseURL}/productosGet.php`
    await fetch(url)
    .then((res) => res.json())
    .then((data) => { 
      setProductList(data.productos || [] )
      
    })
    .catch((error)=>{
      console.log('error en pedir productos', error)
    })
  }

  const crearPedido = async ( data, payment = null) => { 
    if(payment == null) return
    const email = payment.customer_details.email
    const name =  payment.customer_details.name
    const total = payment.amount_total
    const productos = await produtList.map((item)=>{  
      const existProductOnData = data.filter((i)=>i.id == item.id)
      if(existProductOnData.length > 0){ 
        return (
        {
          "cantidad": item?.cantidad,
          "precio": item?.precio,
          "titulo": existProductOnData?.titulo,
          "item":[""],
          "categoria": existProductOnData?.categoria,
          "imagen": existProductOnData?.imagen,
          "ingredientes":item?.ingredientes
        })
      } 
    }) 
     
    const formData = new FormData()

    formData.append('estado','Pendiente')
    formData.append('productos', JSON.stringify(productos) )
    formData.append('total', total || 0)
    formData.append('nombre', name || '')
    formData.append('usuario', idCliente || '')
    formData.append('sucursal', idRestaurate)
    formData.append('nota','')
    formData.append('codigo','')
    formData.append('idStripe',payment.id)
 
    await fetch(`${baseURL}/pedidoPost.php`, {
      method: 'POST',
      body: formData,
    })
    .then((res)=> res.json())
    .then((data)=>{
       console.log('data responsed',data)
       alert(data.mensaje)
       localStorage.removeItem('cart');
       //sendEmail(email,name, data )

    })
    .catch((error)=>{
      console.log('error en crear el pedido', error)
    })
  
  }
  const sendEmail = async (email, name, data)=> {
    let message = `Este es el pedido sin formato`
    message += ``
    data.map( item => {
      message += `cantidad:${item.cantidad} x ${item.idProducto}`
    })
 
    const url = `https://ninjastudio.dev/api/email/email?name=${name}&email=${email}&message=${message}`
    await fetch(url)
    //.then((res) => res.json())
    .then((json) => { 
      console.log('se envio el email',json)
    })
  }


  useEffect(() => {
    async function fetchSession() {
      setSession(
        await fetch('https://chako.ninjastudio.dev/public/checkout-session.php?sessionId=' + sessionId)
        .then((res) => res.json()   )
        .then((json) => {
          if(json.payment_status == 'paid'){
            setPaymentStatus('paid')
            console.log('Pagado solicitando email',json)
            try {
              if(produtList != []){ 
                const pedido = JSON.parse(json.metadata)
                console.log('pedido recibido ',pedido)
                formatedDate()
                setPedidoData({
                  numero: +new Date(),
                  cliente: json.customer_details.name,
                  fecha:  formatedDate(new Date(json.created)),
                  items: pedido.map((i)=>{
                    const producto = produtList.filter(item => item.idProducto == i.idProducto ) 

                    if(producto.length > 0){ 
                      //console.log('pedido', pedido)
                      //console.log('produtList', produtList)
                      return {
                        ...i,
                        precio: parseFloat(i.precio),
                        nombre: producto[0].titulo,
                        sucursal: idRestaurate,
                        notas:''
                      }
                    } else {
                      return {
                        ...i,
                        precio: parseFloat(i.precio),
                        nombre: 'Producto no identificado',
                        sucursal: idRestaurate,
                        notas:''
                      }
                    }
                  }),
                  total: json.amount_total,
                }) 
                //crearPedido(pedido,json) 
              }
            } catch (error) {
              
            }
          }  
        })
      );
    }
    fetchSession();
  }, [sessionId,produtList]);

  useEffect(() => {
      console.log(session)
      setPaymentStatus(session?.payment_status)
  }, [session]);

  useEffect(() => {
    getProductList()
  }, []);

  



  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        {
          paymentStatus == 'paid' &&
          <div className="sr-payment-summary completed-view text-white  flex flex-col items-center mt-3">
            <h1 className='text-xl font-bold text-center'>EL Pago Ha sido Verificado</h1> 
            <Ticket ref={ticketRef} pedido={pedidoData} className='text-white'/>
          </div> 
        }
      </div>
      <div className="sr-content">
        <div className="pasha-image-stack text-center flex flex-col gap-2  w-full items-center font-bold uppercase"> 
          <button className='bg-white w-[300px] uppercase'>Seguir Comprando</button>
          <button className='bg-black text-white w-[300px] uppercase'>Ver mi pedido</button>
        </div>
      </div>
    </div>
  );
};

export default Success;
