import React, { useEffect, useState,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSync, faEye, faArrowUp, faArrowDown, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './ReservasData.css'
import 'jspdf-autotable';
import baseURL from '../../url';
import moneda from '../../moneda';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import contador from '../../contador'
import { formatedDate } from '../../../Helpers/helpers';
import { useReactToPrint } from 'react-to-print';
import Ticket from '../../Ticket/Ticket';


export default function ReservasData() {
    const [reservas, setReservas] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoEstado, setNuevoEstado] = useState('');
    const [reserva, setReserva] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');
    const [mesas, setMesas] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const ticketRef = useRef();
    
    const [filtroId, setFiltroId] = useState('');
    const [filtroMesa, setFiltroMesa] = useState('');
    const [filtroEstado, setFiltroEstado] = useState('Pendiente');
    const [filtroDesde, setFiltroDesde] = useState('');
    const [filtroHasta, setFiltroHasta] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);



    
    const ReservaData = {
        numero: 1234,
        cliente: "Jesus F.",
        fecha:'05-12-2024',
        items: [
            { 
                nombre: 'Hamburguesa', 
                cantidad: 2, 
                precio: 5.99, 
                notas:'Sin cebolla, sin repollo sin mostaza'
            },
            { 
                nombre: 'Papas Fritas', 
                cantidad: 1, 
                precio: 2.49, 
                notas:'Con bastante salsa de tomate'
            },
        ],
        total: 14.47,
    }

    const handlePrint = useReactToPrint({ 
        content: () => ticketRef.current,
    });

    useEffect(() => {
        cargarReservas();
        cargarMesas()
        cargarSucursales()
    }, []);
     
    const cargarMesas = () => {


        fetch(`${baseURL}/mesaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setMesas(data.mesas || []);
                //console.log(data.mesas)
            })
            .catch(error => console.error('Error al cargar mesas:', error));
    };
    
    const cargarSucursales = () => {
        fetch(`${baseURL}/sucursalesGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setSucursales(data.sucursales || []);
                console.log(data.sucursales)
            })
            .catch(error => console.error('Error al cargar sucursales:', error));
    };

    const cargarReservas = () => {
        fetch(`${baseURL}/reservasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setReservas(data.reservas.reverse() || []); 
                //console.log(data.reservas)
            })
            .catch(error => console.error('Error al cargar reservas:', error));
    };

    const getSucursal = ( id , option = false) =>{
        const sucursal = sucursales.filter(s => s.id == id)
        if( sucursal.length > 0 ){
            const completa = sucursal[0]
            if( option != false ){
                return completa[option]
            }
            return completa
        }
        return false
    }

    const eliminar = (idReserva) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/reservaDelete.php?idReserva=${idReserva}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarReservas();
                    })
                    .catch(error => {
                        console.error('Error al eliminar :', error);
                        toast.error(error);
                    });
            }
        });
    }; 
    const abrirModal = (item) => {
        setReserva(item);
        setNuevoEstado(item.estado)
        setModalVisible(true);
    }; 
    const cerrarModal = () => {
        setModalVisible(false);
    }; 
    const handleUpdateText = (idReserva) => {
        const payload = {
            estado: nuevoEstado !== '' ? nuevoEstado : reserva.estado,
        };

        fetch(`${baseURL}/reservaPut.php?idReserva=${idReserva}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarReservas();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };  
    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    const filtrados = reservas.filter(item => { 
        const idMatch = item.id.toString().includes(filtroId); 
        const estadoMatch = !filtroEstado || item.estado.includes(filtroEstado) ;
        const desdeMatch = !filtroDesde || new Date(item.createdAt) >= new Date(filtroDesde);
         // Incrementamos la fecha "hasta" en un día para que incluya la fecha seleccionada
        const adjustedHasta = new Date(filtroHasta);
        adjustedHasta.setDate(adjustedHasta.getDate() + 1);

        const hastaMatch = !filtroHasta || new Date(item.createdAt) < adjustedHasta;
         
        return idMatch  && estadoMatch && desdeMatch && hastaMatch ;
    });


    const recargarProductos = () => {
        cargarReservas();
    };
    const invertirOrden = () => {
        setReservas([...reservas].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const descargarExcel = () => {
        let totalGeneral = 0;

        const data = filtrados?.map(item => {
            const total = parseFloat(item.total); // Convertir a número
            totalGeneral += total;
            const productos = JSON.parse(item.productos);
            const infoProductos = productos?.map(producto => `${producto.categoria} - ${producto.titulo} - ${moneda}${producto.precio} - x${producto.cantidad}  `);
            return {
                'ID Reserva': item.id, 
                'Sucursal': getSucursal(item.sucursal,'nombre'),
                'Estado': item.estado,
                'Cliente': item.nombre_cliente,   
                'Fecha': (item.fecha_entrada)
            };
        });

        // Formatear el total general
        const formattedTotal = `${moneda} ${totalGeneral.toFixed(2)}`;

        // Agregar fila con el total general
        const totalRow = {

            'ID Reserva': '', 
            'Sucursal': '',
            'Estado': '',
            'Cliente': '',
            'Productos': '', 
            'Fecha': ''
        };

        data.push(totalRow);

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'reservas');
        XLSX.writeFile(wb, 'reservas.xlsx');
    };

    function imprimirTicket(){
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        
        mywindow.document.write('------------------------'); 
        mywindow.document.write(document.querySelector('#datos-ticket').innerHTML); 
        mywindow.document.write('------------------------');
        mywindow.focus(); //  Es necesario para internet explorer IE >= 10*/
        mywindow.print();
        mywindow.document.close(); // Es necesario para internet explorer IE >= 10 
        mywindow.close(); 
        return true;
    }

    const descargarPDF = () => {
        const pdf = new jsPDF('landscape'); // Orientación horizontal
        pdf.text('Lista de Reservas', 10, 10);

        const columns = [
            { title: 'ID Reserva', dataKey: 'id' },
            { title: 'Sucursal', dataKey: 'sucursal' },
            { title: 'Estado', dataKey: 'estado' },
            { title: 'Cliente', dataKey: 'nombre_cliente' }, 
            { title: 'Fecha', dataKey: 'fecha_entrada' },
        ];

        let totalGeneral = 0;

        const data = filtrados?.map(item => {
            const total = parseFloat(item.total); // Convertir a número
            totalGeneral += total;
            return {
                idReserva: item.id,
                sucursal: getSucursal(item.sucursal,'nombre'), 
                estado: item.estado,
                cliente: item.nombre_cliente, 
                fecha: item.fecha_entrada,
            };
        });

        // Formatear el total general
        const formattedTotal = `${moneda} ${totalGeneral.toFixed(2)}`;

        // Agregar fila con el total general
        const totalRow = {
            idReserva: '',
            sucursal: '',
            estado: '',
            cliente: '', 
            fecha: '',
        };

        data.push(totalRow);

        pdf.autoTable({
            head: [columns?.map(col => col.title)],
            body: data?.map(item => Object.values(item)),
        });

        pdf.save('reservas.pdf');
    };
    const handleDownloadPDF = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        let y = 10;

        // Agregar título
        pdf.setFontSize(10);


        // Obtener los detalles del reserva actualmente mostrado en el modal
        const ReservaActual = reserva;

        const mesaFiltrada = mesas?.filter(mesa => mesa?.idMesa === ReservaActual?.idMesa)

        // Agregar detalles del reserva al PDF
        const ReservaData = [
            [`ID Reserva:`, `${ReservaActual.id}`],
            [`Sucursal:`, `${ReservaActual.sucursal}`],
            [`Estado:`, `${ReservaActual.estado}`],
            [`Nombre:`, `${ReservaActual.cliente}`], 
            [`Fecha:`, `${(ReservaActual.fecha)}`]
        ];
        pdf.autoTable({
            startY: y,
            head: [['Detalle de la reserva', 'Valor']],
            body: ReservaData,
        });
        y = pdf.autoTableEndPosY() + 5;

        y += 5;

        // Obtener los productos del reserva actual
        const productosReserva = JSON.parse(ReservaActual.productos);

        // Generar sección de productos con imágenes y contenido
        for (let i = 0; i < productosReserva.length; i++) {
            if (y + 30 > pdf.internal.pageSize.getHeight()) {
                pdf.addPage();
                y = 10;
            }
 
            pdf.setFontSize(8); 
             
            y += 25; // Incrementar y para la siguiente posición
        }

        // Guardar el PDF
        pdf.save('reserva.pdf');
    };

    const [counter, setCounter] = useState(contador);
    const [isPaused, setIsPaused] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                setCounter((prevCounter) => {
                    if (prevCounter === 1) {
                        recargar();
                        return contador;
                    }
                    return prevCounter - 1;
                });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPaused]);
    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    
    const cambiarEstado = (idReserva, estado) =>{
        const payload = {
            estado: nuevoEstado !== '' ? estado : estado,
        };

        fetch(`${baseURL}/reservaPut.php?idReserva=${idReserva}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarReservas();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    }
    const recargar = () => {
        cargarMesas();
        cargarReservas();
    };


    return (
        <div>

            <ToastContainer />
            <div className='deFlexContent'>

                <div className='deFlex2'>

                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input type="date" value={filtroDesde} onChange={(e) => setFiltroDesde(e.target.value)} placeholder='Desde' />
                    </div>
                    <div className='inputsColumn'>
                        <input type="date" value={filtroHasta} onChange={(e) => setFiltroHasta(e.target.value)} placeholder='Hasta' />
                    </div>

                    <div className='inputsColumn'>
                        <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='Id Reserva' />
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroMesa} onChange={(e) => setFiltroMesa(e.target.value)}>
                            <option value="">Todas las mesas</option>
                            {
                                mesas?.map(mapeomesa => (
                                    <option value={mapeomesa?.idMesa}>{mapeomesa?.mesa}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
                          
                            <option value="">Todos</option>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Rechazado">Rechazada</option>
                            <option value="Aceptada">Aceptada</option>
                            <option value="Concluida">Concluida</option>
                        </select>
                    </div>
                    <button className='reload' onClick={recargarProductos}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>

            <div className='table-container'>
                <table className='table w-full'>
                    <thead className='w-full'>
                        <tr className='w-full'>
                            <th className='w-1/12'>Id</th> 
                            <th className='w-3/12'>Estado</th>  
                            <th className='w-2/12'>Sucursal</th>  
                            <th className='w-4/12'>Fecha</th>
                            <th className='w-1/12'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody className='w-full'>
                        {filtrados?.map(item => (
                            <tr key={item.id} className='w-full'>
                                <td className='w-2/12'>{item.id}</td> 
                                <td className='w-2/12'>
                                    {<select  className='w-full'
                                        value={item.estado}
                                        onChange={(e) => cambiarEstado(item.id, e.target.value )}
                                    >
                                        <option value={item.estado}>{item.estado}</option>
                                        <option value="Pendiente">Pendiente</option>
                                        <option value="Aceptada">Aceptada</option> 
                                        <option value="Rechazada">Rechazada</option>
                                        <option value="Concluida">Concluida</option>
                                    </select>}
                                </td> 
                                <td className='w-2/12'>{getSucursal(item.sucursal,'nombre') || 'Sin sucursal'}</td>  
                                <td className='w-2/12'>{(item.fecha_entrada)}</td>
                                <td className='flex justify-between w-full'> 
                                    <button className='hover:text-green-500' onClick={handlePrint}>
                                        <FontAwesomeIcon icon={faPrint} />
                                    </button>
                                    <button className='hover:text-red-500' onClick={() => eliminar(item.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='hover:text-blue-500' onClick={() => abrirModal(item)}>

                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Reserva
                                </button>
                                <button onClick={imprimirTicket}>Imprimir Ticket</button>
                                <button onClick={handleDownloadPDF} className='texto'>Descargar PDF</button>
                            </div>

                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flex gap-4 justify-between p-2'> 
                                <div className='flexGrap w-4/6'>
                                    <fieldset>
                                        <legend>ID Reserva</legend>
                                        <input
                                            value={reserva.id}
                                            disabled

                                        />
                                    </fieldset>
                                     
                                    <fieldset>
                                        <legend>Nombre</legend>
                                        <input
                                            value={reserva.nombre}
                                            disabled

                                        />
                                    </fieldset>
  
                                    <fieldset>
                                        <legend>Sucursal</legend>
                                        <input
                                            value={reserva.sucursal}
                                            disabled 
                                        />
                                    </fieldset>
                                    <fieldset>
                                        <legend>Fecha </legend>
                                        <input
                                            value={(reserva.createdAt)}
                                            disabled

                                        />
                                    </fieldset>
                                    <fieldset>
                                        <legend>Total </legend>
                                        <input
                                            value={reserva.total}
                                            disabled

                                        />
                                    </fieldset>
                                    <fieldset>
                                        <legend>Estado</legend>
                                        <select
                                            value={nuevoEstado !== '' ? nuevoEstado : reserva.estado}
                                            onChange={(e) => setNuevoEstado(e.target.value)}
                                        >
                                            <option value={reserva.estado}>{reserva.estado}</option>
                                            <option value="Pendiente">Pendiente</option>
                                            <option value="Entregado">Entregado</option>
                                            <option value="Delivery">En ruta para entrega</option>
                                            <option value="Preparando">Preparando</option>
                                            <option value="Rechazado">Rechazado</option>
                                            <option value="Pagado">Pagado</option>
                                        </select>
                                    </fieldset>
                                    <div className='w-full'> 
                                        <label htmlFor=""> Notas</label>
                                        <textarea 
                                            className='p-2 max-h-[100px]'
                                            value={reserva.nota}
                                            placeholder='Agregue las notas del reserva'
                                            disabled>  
                                        </textarea>
                                    </div>

                                    <div className='cardsProductData'>
                                        {JSON.parse(reserva.productos).map(producto => (
                                            <div key={producto.titulo} className='cardProductData'>
                                                <img src={producto.imagen} alt="imagen" />
                                                <div className='cardProductDataText'>
                                                    <h3>{producto.titulo}</h3>
                                                    <strong>{moneda} {producto.precio} <span>x{producto.cantidad}</span></strong>
                                                    <span>{producto.item}</span>
                                                    <h5>{producto.categoria}</h5>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='w-auto' style={{ display: 'flex ', border:'dashed 2px red' }}>
                                    <Ticket ref={ticketRef} reserva={ReservaData} />
                                </div>   
                            </div>
                            <div className='w-full p-2 flex justify-center'>

                                <button className='btnPost w-full' onClick={() => handleUpdateText(reserva.id)} >Guardar </button>
                            </div>
                        </div>
                        
                         
                    </div>
                </div>
            )}
        </div>
    );
};
